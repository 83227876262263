import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { HighlightsModel } from '@model/highlights.model';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { useColors } from '@hooks/useColors';
import { useCarousel } from '@hooks/useCarousel';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import styles from './HighlightsParagraph.module.scss';

// Can't work with Embla
// const Box = dynamic(() => import('@mui/material/Box'));

const CountUp = dynamic(() => import('react-countup'));
const Icon = dynamic(() => import('@mui/material/Icon'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const GridMask = dynamic(() => import('@atoms/GridMask/GridMask'));
const CustomCard = dynamic(() => import('@atoms/CustomCard/CustomCard'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const ViewContainer = dynamic(() => import('@atoms/ViewContainer/ViewContainer'));
const RichText = dynamic(() => import('@organisms/RichTextParagraph/RichText'));
const IntroParagraph = dynamic(() => import('@organisms/IntroParagraph/IntroParagraph'));

const HighlightsParagraph = ({ fieldParagraph, node }: FieldParagraphProps) => {
  const router = useRouter();

  const data: HighlightsModel = {
    title: fieldParagraph.title,
    transition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class,
    imageUrl: fieldParagraph.field_media ? absoluteUrl(fieldParagraph.field_media?.field_media_image.uri.url) : '',
    imageAlt: '',
    direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
    colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
    items: fieldParagraph.field_items.map((item) => ({
      title: item.field_title,
      description: item.field_description?.processed,
      prefix: item.field_overline,
      suffix: item.field_marquee,
      icon: item.field_media
        ? {
            url: absoluteUrl(item.field_media?.field_media_image.uri.url),
            alt: item.field_media?.field_media_image?.resourceIdObjMeta.alt,
          }
        : null,
    })),
    anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',

    // todo da integrare quando ci sarà il dato da backend
    sdgCards: [],
  };

  const { textColor } = useColors(data?.colorVariant);

  const { emblaRef } = useCarousel({
    dragFree: true,
    skipSnaps: true,
    slidesToScroll: 1,
    align: 'center',
  });

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      transition={data.transition}
      colorVariant={data.colorVariant}
      linesDirection={data.direction}
      isGridMaskDisabled={!!data.imageUrl}
      position="relative"
      py={{ xs: 80, lg: 104 }}
    >
      {data.imageUrl && (
        <>
          <Image style={{ objectFit: 'cover' }} fill src={data.imageUrl} alt={data.imageAlt} />

          <Box
            position="absolute"
            top={0}
            width="100%"
            height="100%"
            sx={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))' }}
          />

          <GridMask colorVariant="dark" direction={data.direction} />
        </>
      )}

      <Box mb={{ xs: theme.spacing(40), lg: theme.spacing(88) }}>
        <IntroParagraph
          node={node}
          removePadding={true}
          isTransitionBoxDisabled={true}
          isBackgroundColorDisabled={true}
          mockDataUrl="/data/highlights/highlights.json"
          fieldParagraph={fieldParagraph}
        />
      </Box>

      {!!data?.sdgCards?.length && (
        <Row
          mb={{ xs: theme.spacing(40), lg: theme.spacing(64) }}
          sx={{
            ':before, :after': {
              content: "''",
              position: 'absolute',
              height: '100%',
              width: theme.spacing(80),
              zIndex: 1,
              top: 0,
            },
            ':before': {
              left: 0,
              background: `linear-gradient(to right, ${
                data?.colorVariant === 'light' ? theme.palette.common.white : theme.palette.common.black
              } 0%, transparent 100%)`,
            },
            ':after': {
              right: 0,
              background: `linear-gradient(to left, ${
                data?.colorVariant === 'light' ? theme.palette.common.white : theme.palette.common.black
              } 0%, transparent 100%)`,
            },
          }}
          ref={emblaRef}
          overflow="hidden"
        >
          <Grid
            item
            xs={12}
            justifyContent="center"
            display="flex"
            flexWrap="nowrap"
            gap={{ xs: theme.spacing(22), lg: theme.spacing(32) }}
          >
            {data?.sdgCards?.map((card, index) => (
              <CustomCard
                key={index}
                variant="sdg-large-card"
                title={card.title}
                label={card.label}
                imageAlt={card.imageAlt}
                imageUrl={card.imageUrl}
                caption={card.caption}
              />
            ))}
          </Grid>
        </Row>
      )}

      <ViewContainer>
        {(isInView) => (
          <GridContainer>
            <FadeIn>
              <Row rowGap={{ xs: theme.spacing(24), lg: 0 }} justifyContent="center">
                {data.items.map((item, index) => (
                  <Grid key={index} position="relative" item xs={12} pt={105} pb={23} lg={3} textAlign="center">
                    <Box position="relative" sx={{ top: { xs: 'inherit', lg: index % 2 !== 0 ? -50 : 0 } }}>
                      <Box
                        className={`${styles[`Bubble${index + 1}`]} ${
                          data.colorVariant === 'dark' ? styles[`Bubble${index + 1}Dark`] : ''
                        } ${data.imageUrl ? styles[`Bubble${index + 1}Image`] : ''}`}
                      />

                      <Box position="relative">
                        {item.title && isInView && (
                          <>
                            <Typography variant="h2" component="span" color={textColor} fontWeight={500}>
                              {item.prefix}
                            </Typography>
                            <Typography variant="h2" component="span" color={textColor} fontWeight={500}>
                              <CountUp
                                separator={router.locale == 'en' ? ',' : '.'}
                                end={+item.title.replace(/[^0-9.]/g, '')}
                                useEasing={true}
                                redraw={false}
                                duration={2}
                                delay={0.5}
                                decimals={item.title.indexOf('.') >= 0 ? 1 : 0}
                                decimal={item.title.indexOf('.') >= 0 ? (router.locale == 'en' ? '.' : ',') : ''}
                                // prefix={getPrefix(item.title)}
                                // suffix={getSuffix(item.title)}
                              />
                            </Typography>
                            <Typography variant="h2" component="span" color={textColor} fontWeight={500}>
                              {item.suffix}
                            </Typography>
                          </>
                        )}

                        {item?.icon?.url && (
                          <Image src={item?.icon?.url} alt={item?.icon?.alt} width={72} height={72} />
                        )}

                        <Typography variant="bodyL" color="grey.600" component="p" my={4}>
                          + + +
                        </Typography>

                        {item.description && (
                          <Typography
                            variant="bodyM"
                            component="div"
                            color={textColor}
                            marginBottom={4}
                            sx={{
                              'p.MuiTypography-root.MuiTypography-bodyL': {
                                fontSize: ' 0.875rem',
                                lineHeight: '1.3125rem',
                              },
                              '@media (min-width: 992px)': {
                                'p.MuiTypography-root.MuiTypography-bodyL': {
                                  fontSize: '1rem',
                                  lineHeight: '1.5rem',
                                },
                              },
                            }}
                          >
                            <RichText text={item.description} />
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Row>
            </FadeIn>
          </GridContainer>
        )}
      </ViewContainer>
    </BackgroundTransitionBox>
  );
};

export default HighlightsParagraph;
